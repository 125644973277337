import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import ServiceFooter from '../../components/ServiceFooter'

const Bicycle = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`駐輪管理システム | ${title}`}</title>
        <meta
          name="description"
          content="駐輪用ロックシステムの電磁ロック式駐輪機を2種類ご用意しました。"
        />
      </Helmet>
      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>駐輪管理システム</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li>駐輪管理システム</li>
        </div>
      </div>

      <div className="main-wrapper bicycle">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>駐輪用ロックシステム</h2>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <h4>電磁ロック式駐輪機を2種類ご用意しました</h4>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>TYPE 1</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-01">
            <div className="separate-area">
              <div className="box">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-bicycle-01.webp"
                  />
                  <img
                    className="bicycle-01"
                    src="/assets/images/common/service-bicycle-01.png"
                    height="579"
                    alt=""
                  />
                </picture>
                <p>外観写真</p>
              </div>
              <div className="box">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-bicycle-02.webp"
                  />
                  <img
                    className="bicycle-02"
                    src="/assets/images/common/service-bicycle-02.png"
                    height="579"
                    alt=""
                  />
                </picture>
                <p>外観図</p>
              </div>
            </div>

            <h5>特徴</h5>
            <ul className="small-points">
              <li>入庫自転車を検知しロックする機構です。</li>
              <li>シングルキャッチレバー（左側出）1枚</li>
              <li>対応自転車：20～28インチ・タイヤ幅60mm以内</li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>TYPE 2</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-02">
            <div className="separate-area">
              <div className="box">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-bicycle-03.webp"
                  />
                  <img
                    className="bicycle-03"
                    src="/assets/images/common/service-bicycle-03.png"
                    height="660"
                    alt="TYPE 2"
                  />
                </picture>
                <p>外観写真</p>
              </div>
              <div className="box">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/service-bicycle-04.webp"
                  />
                  <img
                    className="bicycle-04"
                    src="/assets/images/common/service-bicycle-04.png"
                    height="250"
                    alt="TYPE 2説明"
                  />
                </picture>
                <p>左右からの２本のツメがタイヤをロック</p>
              </div>
            </div>

            <h5>特徴</h5>
            <ul className="small-points">
              <li>
                高低交互に配列、かつ左右にスイングするため、両隣りに自転車がある場合にもスムーズに出し入れが可能です。
              </li>
              <li>対応自転車：16～28インチ・タイヤ幅53mm以内</li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>TYPE 1・TYPE 2 共通仕様</h3>
          </div>
        </div>

        <div className="row">
          <div className="container spec">
            <table>
              <tbody>
                <tr>
                  <th>電源電圧</th>
                  <td>DC-24V (上位機器からの給電による)</td>
                </tr>
                <tr>
                  <th>使用環境</th>
                  <td className="multi">
                    温度：0～45℃
                    <br />
                    湿度：30～90％RH（結露しないこと）
                    <br />
                    屋内外設置可能（防滴構造）
                  </td>
                </tr>
                <tr>
                  <th>車両検知方式</th>
                  <td>
                    ラックレール侵入による前輪のロックおよび精算による自動解放機能
                  </td>
                </tr>
                <tr>
                  <th>ラック間隔</th>
                  <td>標準450mm（高位用・低位用の交互配列）</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Bicycle
